@use '../../../../styles/animations.scss';

.eventOverview {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5vh;
  min-height: 65vh;
  width: 100%;
  height: 100%;
}

.wrapper {
  display: flex;
  width: 100%;
  min-height: 70vh;
}

.events {
  display: flex;
  flex-direction: column;
}

.animation {
  animation: 1.5s 1 normal fadeIn;
}

.upcomingEventOverviewTitle {
  text-align: center;
}

.upcomingEventOverviewContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
}

.eventOverview {
  display: flex;
  flex-direction: column;
  min-height: 50vh;
}

.eventContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.eventOverviewContainer {
  display: flex;
  flex-direction: column;
}

.eventOverviewTitles {
  display: flex;
  width: 50vw;
  flex-direction: row;
  white-space: pre-wrap;

  h6 {
    font-size: 20px;
    font-weight: normal;
    cursor: pointer;
    margin: 1rem 0rem 1rem;
  }
}

.itemRight {
  flex-basis: 100%;
  text-align: end;
  h6 {
    padding-right: 1rem;
  }
}

.itemMiddle {
  flex-basis: 100%;
  text-align: center;
  h6 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.itemLeft {
  flex-basis: 100%;
  text-align: start;
  h6 {
    padding-left: 1rem;
  }
}
.headerButton {
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-right: 0.5rem;
}
.paginationControls {
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 20px;
  margin-bottom: 20px;
}
.pageButton {
  background: none;
  border: 1px solid #ccc;
  color: white;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  min-width: 2rem;
  text-align: center;
}

.pageButton.active {
  background-color: #2b2c3d;
  color: white;
  border: 1px solid white;
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

@media screen and (max-width: 768px) {
  .eventOverviewContainer {
    width: 95%;
  }

  .mainTitle {
    h2 {
      font-size: 2.75rem;
    }
  }

  .eventOverviewTitles {
    width: 75vw;
    h6 {
      font-size: 14px;
    }
  }
  .itemLeft {
    text-align: center;
    h6 {
      padding-left: 0.5rem;
    }
  }
  .itemMiddle {
    h6 {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }
  }
  .itemRight {
    text-align: center;
    h6 {
      padding-right: 0.5rem;
    }
  }
}
